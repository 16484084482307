const teamData = {
  Leadership: [
    {
      positions: ['Founder'],
      name: 'Sahil Gulati',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Founder'],
      name: 'Tushar Gupta',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['President'],
      name: 'Shakib Ahmed',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Director'],
      name: 'Devesh Garg',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Director'],
      name: 'Prakash Ujjwal',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Tech Lead'],
      name: 'Prince Bansal',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Tech Lead'],
      name: 'Divij Gera',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Tech Lead', 'Content Creation Lead'],
      name: 'Urja Banati',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
  ],
  'WebD Team': [
    {
      positions: ['President', 'WebD Lead'],
      name: 'Shakib Ahmed',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Director','WebD Lead'],
      name: 'Prakash Ujjwal',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['WebD Lead'],
      name: 'Prince Bansal',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['WebD Lead'],
      name: 'Divij Gera',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
  ],
  'AppD Team': [
    {
      positions: ['AppD Lead', 'Content Creation Lead'],
      name: 'Urja Banati',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
  ],
  'Content Managers': [
    {
      positions: ['Director','Content Management Lead'],
      name: 'Devesh Garg',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Content Management Lead'],
      name: 'Harshita Yadav',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
  ],
  'Content Writers': [
    {
      positions: ['AppD Lead', 'Content Creation Lead'],
      name: 'Urja Banati',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
  ],
  Alumni: [
    {
      positions: ['Founder'],
      name: 'Sahil Gulati',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Founder'],
      name: 'Tushar Gupta',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former President'],
      name: 'Shrey Jain',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former Director', 'Former Content Creation Lead'],
      name: 'Divya Rawat',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former Director', 'Former AppD Lead'],
      name: 'Mohit Yadav',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former Tech Lead'],
      name: 'Anish Jangra',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former Tech Lead'],
      name: 'Shubham',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former Content Management Lead'],
      name: 'Jigyasu Malhotra',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
    {
      positions: ['Former Content Creation, Marketing & PR Lead'],
      name: 'Avanik Gupta',
      image:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      chipImage:
        'https://res.cloudinary.com/neo1729/image/upload/v1613712054/CollegeSpace%20Feb%202021/logo_xeqw2n.png',
      bio: '',
    },
  ],
};

export default teamData;
